import {
  Button,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

export function FieldJSONGeneratorPage() {
  return (
    <>
      <TextField label="Name" />
      <Stack>
        <Typography>Required</Typography>
        <Checkbox />
      </Stack>
      <Stack>
        <Typography>Sortable</Typography>
        <Checkbox />
      </Stack>
      <Stack>
        <Typography>Groupable</Typography>
        <Checkbox />
      </Stack>
      <Stack>
        <Typography>Multiple</Typography>
        <Checkbox />
      </Stack>
      <TextField label="Order" />
      <TextField label="Type" select>
        <MenuItem value="string">String</MenuItem>
        <MenuItem value="number">Number</MenuItem>
        <MenuItem value="boolean">Boolean</MenuItem>
        <MenuItem value="object">Object</MenuItem>
        <MenuItem value="date">Date</MenuItem>
        <MenuItem value="enum">Enum</MenuItem>
        <MenuItem value="entity">Entity</MenuItem>
        <MenuItem value="computed">Computed</MenuItem>
      </TextField>
      <Stack>
        <Typography>Default</Typography>
        <TextField label="Type" select>
          <MenuItem value="">Unset</MenuItem>
          <MenuItem value="string">String</MenuItem>
          <MenuItem value="number">Number</MenuItem>
          <MenuItem value="boolean">Boolean</MenuItem>
        </TextField>
        <TextField label="Value" />
      </Stack>
      <Stack>
        <Typography>Properties</Typography>
        <TextField label="Type" select>
          <MenuItem value="array">Array</MenuItem>
          <MenuItem value="object">Object</MenuItem>
        </TextField>
        <Button>Add New Property</Button>
      </Stack>
      <Stack>
        <Typography>UI Options</Typography>
        <TextField label="Display Name" />
        <Stack>
          <Typography>Visibility</Typography>

          <Stack>
            <Typography>Card</Typography>
            <Checkbox />
          </Stack>
          <Stack>
            <Typography>List</Typography>
            <Checkbox />
          </Stack>
          <Stack>
            <Typography>Entity Main</Typography>
            <Checkbox />
          </Stack>
          <Stack>
            <Typography>Entity Sidebar</Typography>
            <Checkbox />
          </Stack>
        </Stack>
      </Stack>
      <Stack>
        <Typography>Adornments</Typography>
        <Stack>
          <Typography>Start</Typography>

          <TextField label="Text" />
          <TextField label="Icon" />
          <TextField label="Color" />
        </Stack>
        <Stack>
          <Typography>End</Typography>
          <TextField label="Text" />
          <TextField label="Icon" />
          <TextField label="Color" />
        </Stack>
      </Stack>
      <Stack>
        <Typography>Constraints</Typography>
        <Stack>
          <TextField label="Name" />
          <TextField label="Property" />
          <TextField label="Operator" select>
            <MenuItem value="equals">Equals</MenuItem>
            <MenuItem value="not_equals">Not Equals</MenuItem>
            <MenuItem value="in">In</MenuItem>
            <MenuItem value="not_in">Not In</MenuItem>
          </TextField>
          <Stack>
            <Typography>Value</Typography>
            <Stack>
              <Typography>Array?</Typography>
              <Checkbox />
            </Stack>
            <Stack>
              <TextField label="Type" select>
                <MenuItem value="string">String</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="boolean">Boolean</MenuItem>
              </TextField>
              <TextField label="Value" />
            </Stack>
          </Stack>
        </Stack>
        <Button>Add New Constraint</Button>
      </Stack>
      <Stack>
        <Typography>Meta</Typography>
        <TextField label="Raw" />
        <Stack>
          <Typography>Computed Field Type Meta</Typography>
          <TextField label="Type" select>
            <MenuItem value="number">Number</MenuItem>
          </TextField>
          <TextField label="Function" select>
            <MenuItem value="sum">Sum</MenuItem>
          </TextField>
          <Stack>
            <Typography>Filter</Typography>
            <TextField label="Type" select>
              <MenuItem value="children">Children</MenuItem>
              <MenuItem value="constraints">Constraints</MenuItem>
            </TextField>

            <Stack>
              <Typography>Constraints</Typography>
              <Button>Add New Constraint</Button>
            </Stack>
          </Stack>
          <Stack>
            <Typography>Dependencies</Typography>
            <TextField label="Value"></TextField>
            <Button>Add New Dependency</Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
