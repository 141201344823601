import pluralize from 'pluralize';
import {
  FinsightEntity,
  FinsightEntityField,
  getEntityExtraFieldValue,
} from 'shared';

export function plural(text: string) {
  return pluralize(text);
}

export function sortFields(fields: FinsightEntityField[]) {
  return Array.from(fields).sort((a, b) =>
    b.schema.groupable ? 1 : (b.schema.order ?? 0) - (a.schema.order ?? 0),
  );
}

export function formatName(name: string) {
  return name
    .split(/\_|\./g)
    .map((x) => x.replace(x[0], x[0]?.toUpperCase()))
    .join(' ');
}

export function formatNameReverse(name: string) {
  //
  return name
    .toLowerCase()
    .split(' ')
    .join('_')
    .replace(/[^a-zA-Z0-9_]/g, '');
}

export function getCommonSortableFields(entities: FinsightEntity[]) {
  if (entities.length < 2) return [];
  const otherEntities = entities.slice(1);
  return Object.keys(entities[0].extraFieldsMeta).filter((field) => {
    for (const entity of otherEntities) {
      if (!(field in entity.extraFieldsMeta)) {
        return false;
      }

      const aField = entities[0].extraFieldsMeta[field];
      const bField = entity.extraFieldsMeta[field];

      if (
        aField.name !== bField.name ||
        aField.schema.type !== bField.schema.type ||
        aField.schema.order !== bField.schema.order ||
        !(
          (aField.schema.groupable && bField.schema.groupable) ||
          (aField.schema.sortable && bField.schema.sortable)
        )
      ) {
        return false;
      }
    }

    return true;
  });
}

export function sortEntities(_a: FinsightEntity, _b: FinsightEntity) {
  const a = _b;
  const b = _a;
  // find fields from a and b that match on name, type, and order

  const commonFieldNames = getCommonSortableFields([a, b]);
  // const commonFieldNames = ['status', 'priority', 'order'];

  if (!commonFieldNames.length) {
    // if no fields to match, sort by name, change this later to preserve original order
    return b.name.localeCompare(a.name);
  }

  for (const fieldName of commonFieldNames) {
    const aField = a.extraFieldsMeta[fieldName];
    const bField = b.extraFieldsMeta[fieldName];

    const aValue = getEntityExtraFieldValue(aField, a.extraFields[fieldName]);

    const bValue = getEntityExtraFieldValue(bField, b.extraFields[fieldName]);

    const type = aField.schema.type;

    switch (type) {
      case 'string':
        const aValueString = `${aValue}`;
        const bValueString = `${bValue}`;

        if (aValueString !== bValueString) {
          return aValueString.localeCompare(bValueString);
        }
        break;
      case 'number':
        const aValueNumber = Number(aValue);
        const bValueNumber = Number(bValue);

        if (aValueNumber !== bValueNumber) {
          return aValueNumber - bValueNumber;
        }
        break;
      case 'boolean':
        const aValueBoolean = Boolean(aValue);
        const bValueBoolean = Boolean(bValue);

        if (aValueBoolean !== bValueBoolean) {
          return aValueBoolean ? -1 : 1;
        }
        break;
      case 'date':
        const aValueDate = new Date(`${aValue}`);
        const bValueDate = new Date(`${bValue}`);

        if (aValueDate.getTime() !== bValueDate.getTime()) {
          return aValueDate.getTime() - bValueDate.getTime();
        }
        break;
      case 'object':
        const aValueObject = JSON.stringify(aValue);
        const bValueObject = JSON.stringify(bValue);

        if (aValueObject !== bValueObject) {
          return aValueObject.localeCompare(bValueObject);
        }
        break;
      case 'enum':
        const aValueEnum = Number(aValue);
        const bValueEnum = Number(bValue);

        if (
          Array.isArray(aField.schema.properties) &&
          Array.isArray(bField.schema.properties) &&
          aField.schema.properties.length === bField.schema.properties.length &&
          aValueEnum !== bValueEnum &&
          !isNaN(aValueEnum)
        ) {
          return (
            (aField.schema.properties[aValueEnum].order ?? 0) -
            (bField.schema.properties[bValueEnum].order ?? 0)
          );
        }
    }
  }

  return b.name.localeCompare(a.name);
}
