import EditableTextField from 'components/editableTextField';
import React from 'react';
import { FinsightEntity } from 'shared';

export default function TextView({
  entities,
}: {
  entities?: FinsightEntity[];
}) {
  return (
    <EditableTextField
      variant="outlined"
      value={entities?.map((child) => child.name).join('\n')}
    />
  );
}
