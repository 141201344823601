import { Search } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEntityActions, useEntityExtraFields } from 'api/entity';
import { useWorkspaceId } from 'api/providers/workspaceProvider';
import EditableTextField from 'components/editableTextField';
import { useCurrentModal, useModal } from 'components/modal';
import { FinsightEntityFieldValue } from 'components/renderField';
import useIsMobile from 'hooks/useIsMobile';
import React, { useMemo, useState } from 'react';
import {
  FinsightEntity,
  FinsightEntityCreateRequest,
  getEntityExtraFieldValue,
} from 'shared';
import { useSWRConfig } from 'swr';
import { formatName, sortFields } from 'utils/fields';

type CreateEntityProps = {
  type: string;
  parent?: FinsightEntity;
  moreClicked?: boolean;
};

export function CreateEntityModal(props: CreateEntityProps) {
  const modalCtx = useCurrentModal();

  const [entity, setEntity] = useState<FinsightEntityCreateRequest>({
    name: '',
    type: props.type,
    extraFields: {},
    parent: props.parent?.id,
  });

  const workspaceId = useWorkspaceId();
  const extraFields = useEntityExtraFields(entity.type);
  const { createEntity } = useEntityActions();

  const [loading, setLoading] = useState(false);
  const { mutate, cache } = useSWRConfig();

  const [error, setError] = useState<string | null>(null);

  const isMobile = useIsMobile();

  const areValuesValid = useMemo(() => {
    try {
      for (const field of extraFields.data ?? []) {
        getEntityExtraFieldValue(field, entity.extraFields?.[field.name]);
      }

      return true;
    } catch {
      return false;
    }
  }, [entity.extraFields, extraFields.data]);

  const canSubmit = entity.name.length && areValuesValid;

  return (
    <Stack gap={2} pt={2}>
      {error && (
        <Stack direction="row" width="100%">
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ flexDirection: 'row !important', width: '100%' }}
          >
            {error}
          </Alert>
        </Stack>
      )}
      <Stack gap={1} direction="row" alignItems="center">
        <Typography variant="h5">New</Typography>
        <EditableTextField
          value={entity.type}
          variant="minimal"
          getLabel={(v) => formatName(v)}
          onChange={async (e) => setEntity({ ...entity, type: e })}
          sx={{
            width: 'unset',
            '& .MuiInputBase-root:not(#a):not(:hover)::before': {
              borderBottom: '1px dashed white',
            },
          }}
          inputProps={{
            sx: {
              fontSize: 'h5.fontSize',
              lineHeight: '1em',
            },
          }}
        />
      </Stack>
      <Typography variant="h6">
        {props.parent ? `Creating under ${props.parent.name}` : 'Root Level'}
      </Typography>
      <Stack direction={isMobile ? 'column' : 'row'} gap={2}>
        <Stack flexGrow={1} gap={2}>
          <TextField
            value={entity.name}
            label="Name"
            variant="outlined"
            fullWidth
            onChange={(e) => setEntity({ ...entity, name: e.target.value })}
          />
          <TextField
            multiline
            minRows={4}
            value={entity.description}
            label="Description"
            variant="outlined"
            fullWidth
            onChange={(e) =>
              setEntity({ ...entity, description: e.target.value })
            }
          />

          {sortFields(extraFields.data ?? []).map((field) => (
            <FinsightEntityFieldValue
              viewType="entity_main"
              editable
              source="create_modal"
              field={field}
              key={field.name}
              value={entity.extraFields?.[field.name] ?? null}
              onChange={async (v) =>
                setEntity({
                  ...entity,
                  extraFields: {
                    ...entity.extraFields,
                    [field.name]: v,
                  },
                })
              }
            />
          ))}
        </Stack>
        {!!extraFields.data?.length && (
          <Stack flex="0 0 300px" gap={2}>
            {sortFields(extraFields.data ?? []).map((field) => (
              <FinsightEntityFieldValue
                viewType="entity_sidebar"
                source="create_modal"
                editable
                field={field}
                key={field.name}
                value={entity.extraFields?.[field.name] ?? null}
                onChange={async (v) =>
                  setEntity({
                    ...entity,
                    extraFields: {
                      ...entity.extraFields,
                      [field.name]: v,
                    },
                  })
                }
              />
            ))}
          </Stack>
        )}
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button color="error" onClick={() => modalCtx?.closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={loading || !canSubmit}
          variant="outlined"
          onClick={async () => {
            setLoading(true);
            try {
              const response = await createEntity(entity);
              if (!response.ok) throw new Error();

              modalCtx?.closeModal();

              Array.from(cache.keys())
                .filter(
                  (x) =>
                    x.includes(
                      `/workspace/${workspaceId}/entities/${entity.type}`,
                    ) ||
                    (props.parent &&
                      (x.includes(
                        `/workspace/${workspaceId}/entity/${props.parent?.id}/children`,
                      ) ||
                        x.includes(
                          `/workspace/${workspaceId}/entity/${props.parent?.id}`,
                        ))),
                )
                .forEach((key) => mutate(key));
            } catch (e) {
              setError('Failed to create entity');
            } finally {
              setLoading(false);
            }
          }}
        >
          Create
        </Button>
      </Stack>
    </Stack>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CreateEntityModalMore(props: CreateEntityProps) {
  return (
    <Stack py={1}>
      <Typography variant="h5">Entity Types</Typography>
      <Typography>
        Entities in finsight can have ANY type. Although finsight does provide
        some basic types such as group, item, and project, you can create your
        own custom types with their own fields and functionality.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Stack mt={2} direction="row" justifyContent="stretch" gap={1}>
        <Stack minWidth={300} overflow="auto">
          <Autocomplete
            sx={{ pt: 1 }}
            freeSolo
            size="small"
            options={[]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Entity Types"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <Search fontSize="small" color="primary" />,
                }}
              />
            )}
          />
          <List dense>
            <Typography
              color="text.secondary"
              textTransform="uppercase"
              variant="body2"
              my={1}
              fontWeight="bold"
            >
              Premade Types
            </Typography>
            <ListItemButton>
              <ListItemText primary="Group" />
            </ListItemButton>
            <ListItemButton>
              <ListItemText primary="Item" />
            </ListItemButton>
            <Typography
              color="text.secondary"
              textTransform="uppercase"
              variant="body2"
              fontWeight="bold"
              my={1}
            >
              Types from plugins
            </Typography>
            <ListItemButton>
              <ListItemText primary="Project" />
            </ListItemButton>
            <ListItemButton>
              <ListItemText primary="Task" />
            </ListItemButton>
            <ListItemButton>
              <ListItemText primary="Transaction" />
            </ListItemButton>
            <ListItemButton>
              <ListItemText primary="Commodity" />
            </ListItemButton>
          </List>
        </Stack>
        <Stack>
          <Divider orientation="vertical" />
        </Stack>
        <Stack flexGrow={1}>
          <Typography>Fields</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function CreateEntityModalMain(props: CreateEntityProps) {
  if (props.moreClicked) {
    return <CreateEntityModalMore {...props} />;
  }

  return <CreateEntityModal {...props} />;
}

export function useCreateEntityModal() {
  const { showModal } = useModal();

  return {
    showCreateEntityModal: (
      type: string,
      parent?: FinsightEntity,
      options?: {
        moreClicked?: boolean;
      },
    ) => {
      showModal(
        '',
        <CreateEntityModalMain
          type={type}
          parent={parent}
          moreClicked={options?.moreClicked}
        />,
        '',
        {
          sx: {
            flex: '0 1 1000px',
          },
        },
      );
    },
  };
}
