import {
  Build,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PushPin,
  RemoveCircleOutline,
} from '@mui/icons-material';
import {
  Badge,
  Button,
  Divider,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useModal } from 'components/modal';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import {
  ComputedFieldMetaSchema,
  FinsightEntityField,
  getEntityExtraFieldValue,
} from 'shared';
import { formatName } from 'utils/fields';

import { BooleanRenderer } from './boolean';
import { DateRenderer } from './date';
import { EntityRenderer } from './entity';
import { EnumRenderer } from './enum';
import { NumberRenderer } from './number';
import { StringRenderer } from './string';

type ViewType = 'list' | 'card' | 'entity_main' | 'entity_sidebar' | 'table';

export function FinsightGetEntityFieldsByViewType(
  viewType: ViewType,
  field: FinsightEntityField,
) {
  if (viewType === 'list' && !field.schema.ui?.visibility?.list) {
    return false;
  }

  if (viewType === 'card' && !field.schema.ui?.visibility?.card) {
    return false;
  }

  if (
    viewType === 'entity_main' &&
    (field.schema.ui?.visibility?.entity_main === false ||
      field.schema.ui?.visibility?.entity_sidebar === true)
  ) {
    return false;
  }

  if (
    viewType === 'entity_sidebar' &&
    (field.schema.ui?.visibility?.entity_sidebar !== true ||
      field.schema.ui?.visibility?.entity_main === true)
  ) {
    return false;
  }

  return true;
}

export function FinsightEntityFieldValue({
  field,
  value: _value,
  viewType,
  editable,
  source,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
}: {
  field: FinsightEntityField;
  value: unknown;
  viewType: ViewType;
  source?: 'create_modal' | 'table';
  editable?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  const isMobile = useIsMobile();
  const { showModal } = useModal();
  if (viewType === 'list' && !field.schema.ui?.visibility?.list) {
    return null;
  }

  if (viewType === 'card' && !field.schema.ui?.visibility?.card) {
    return null;
  }

  if (
    viewType === 'entity_main' &&
    (field.schema.ui?.visibility?.entity_main === false ||
      field.schema.ui?.visibility?.entity_sidebar === true)
  ) {
    return null;
  }

  if (
    viewType === 'entity_sidebar' &&
    (field.schema.ui?.visibility?.entity_sidebar !== true ||
      field.schema.ui?.visibility?.entity_main === true)
  ) {
    return null;
  }

  let value: unknown = null;

  try {
    value = getEntityExtraFieldValue(field, _value);
  } catch {
    if (editable) {
      value = null;
    } else {
      return <Typography>Invalid value</Typography>;
    }
  }

  return (
    <Badge
      badgeContent={
        source !== 'create_modal' &&
        source !== 'table' &&
        editable && (
          <IconButton
            size="small"
            className="more-button"
            onClick={() =>
              showModal(
                'Field Properties',
                <FieldInfo field={field} />,
                'Note: this is an advanced feature. If you are intending to edit the value of the field, please click on the textbox itself instead of the wrench icon.',
              )
            }
          >
            <Build />
          </IconButton>
        )
      }
      sx={{
        '& > *': {
          flexGrow: 1,
        },
        '& .more-button': {
          opacity: isMobile ? 1 : 0,
        },
        '&:hover .more-button': {
          opacity: 1,
        },
        '.MuiBadge-badge': {
          display:
            source !== 'create_modal' && source !== 'table' && editable
              ? 'block'
              : 'none',
        },
        ...(source === 'table'
          ? {
              '.MuiFormLabel-root': {
                display: 'none',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }
          : {}),
      }}
    >
      <FinsightEntityFieldValueRenderer
        editable={editable}
        field={field}
        value={value}
        onChange={onChange}
      />
    </Badge>
  );
}

function FieldInfo(props: { field: FinsightEntityField }) {
  const { showModal } = useModal();
  const { field } = props;

  const isPinned = field.schema.ui?.visibility?.entity_main !== false;

  return (
    <>
      <Typography variant="h6">Visibility</Typography>
      <ListItemButton
        onClick={() =>
          showModal(
            'Pin Field',
            <FieldInfoVisibilityPinnedModal field={field} />,
          )
        }
      >
        <ListItemIcon>
          <PushPin />
        </ListItemIcon>
        <ListItemText
          primary="Pin Field"
          secondary="Pin this field to the top of the entity view"
        />
        <ListItemSecondaryAction
          sx={{
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
          }}
        >
          <Switch checked={isPinned} />
        </ListItemSecondaryAction>
      </ListItemButton>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">Field Info</Typography>
      <Typography>{field.name}</Typography>
      <Typography>{field.type}</Typography>
      <Typography>{field.schema.type}</Typography>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FieldInfoVisibilityPinnedModal(props: { field: FinsightEntityField }) {
  const isPinnedDefault =
    props.field.schema.ui?.visibility?.entity_main !== false;

  const isPinned = isPinnedDefault;

  return (
    <Stack
      sx={{
        overflowY: 'auto',
      }}
    >
      <Typography>
        Field is currently
        {isPinned ? ' pinned' : ' not pinned'}
      </Typography>
      {isPinnedDefault && (
        <Typography>
          The field is currently pinned due to default behaviour
        </Typography>
      )}
      <Typography>
        You can set the field as pinned or unpinned using the button below:
      </Typography>

      <Button variant="outlined">{isPinned ? 'Unpin' : 'Pin'}</Button>
      <Divider
        sx={{
          my: 5,
        }}
      >
        Examples
      </Divider>
      <Typography variant="h6">Desktop</Typography>
      <Typography>
        Below is an example of what pinned fields look like on desktop
      </Typography>
      <img src="/assets/images/field-editor/pin-desktop-comparison.png" />
      <Typography variant="h6">Mobile</Typography>
      <Typography>
        Below is an example of what pinned fields look like on mobile
      </Typography>
      <img
        src="/assets/images/field-editor/pin-mobile-comparison.png"
        width="300"
      />
    </Stack>
  );
}

function FinsightEntityFieldValueRenderer({
  field,
  value,
  editable,
  onChange,
  readonly,
}: {
  field: FinsightEntityField;
  value: unknown;
  editable?: boolean;
  readonly?: boolean;
  onChange?: (value: unknown) => Promise<void>;
}) {
  if (field.schema.multiple) {
    return (
      <Stack border="1px solid" borderColor="divider" p={2} borderRadius={1}>
        <Typography
          variant="caption"
          mt={-3.4}
          mb={1}
          ml={-1}
          bgcolor="background.default"
          width="fit-content"
          px={0.5}
          color="text.secondary"
        >
          {formatName(field.name)}
        </Typography>
        <Stack gap={1}>
          {Array.isArray(value) && !!value.length
            ? value.map((x, i) => (
                <Stack direction="row" key={i} alignItems="center" gap={1}>
                  <Typography minWidth="15px">{i + 1}.</Typography>
                  <Stack
                    sx={{
                      '& > *': {
                        margin: 0,
                        width: 24,
                        height: 24,
                      },
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (onChange && i > 0) {
                          const newValue = [...value];
                          const temp = newValue[i];
                          newValue[i] = newValue[i - 1];
                          newValue[i - 1] = temp;
                          onChange(newValue);
                        }
                      }}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (onChange && i < value.length - 1) {
                          const newValue = [...value];
                          const temp = newValue[i];
                          newValue[i] = newValue[i + 1];
                          newValue[i + 1] = temp;
                          onChange(newValue);
                        }
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Stack>
                  <Stack flexGrow={1}>
                    <FinsightEntityFieldValueRenderer
                      field={{
                        ...field,
                        schema: {
                          ...field.schema,
                          multiple: false,
                        },
                      }}
                      value={x}
                      editable={editable}
                      onChange={async (v) => {
                        if (onChange) {
                          const newValue = [...value];
                          newValue[i] = v;
                          await onChange(newValue);
                        }
                      }}
                    />
                  </Stack>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => {
                      if (onChange) {
                        const newValue = [...value];
                        newValue.splice(i, 1);
                        onChange(newValue);
                      }
                    }}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </Stack>
              ))
            : 'No Items'}
        </Stack>
        <Divider>
          <Typography variant="caption" color="text.secondary">
            Add New
          </Typography>
        </Divider>
        <FinsightEntityFieldValueRenderer
          key={Math.random()}
          field={{
            ...field,
            schema: {
              ...field.schema,
              multiple: false,
            },
          }}
          value={null}
          editable={editable}
          onChange={async (v) => {
            if (onChange) {
              const valueArray = Array.isArray(value) ? value : [];
              const newValue = [...valueArray, v];
              await onChange(newValue);
            }
          }}
        />
      </Stack>
    );
  }

  if (field.schema.type === 'computed') {
    try {
      const metricMeta = ComputedFieldMetaSchema.parse(field.schema.meta);

      const metricType = metricMeta.type;

      return (
        <FinsightEntityFieldValueRenderer
          readonly
          value={value}
          editable={editable}
          field={{
            ...field,
            schema: {
              ...field.schema,
              type: metricType,
            },
          }}
        />
      );
    } catch {
      return <Typography>Invalid Metric schema</Typography>;
    }
  }

  if (field.schema.type === 'object') {
    if (
      typeof (value ?? null) !== 'object' ||
      !Array.isArray(field.schema.properties)
    ) {
      return <Typography>Invalid schema</Typography>;
    }

    const valueMap = (value ?? {}) as Record<string, unknown>;

    if (editable) {
      return (
        <Stack
          display="grid"
          gap={1}
          gridTemplateColumns="repeat(auto-fill, minmax(400px, 1fr))"
          justifyContent="stretch"
        >
          {field.schema.properties?.map((property, i) => {
            if (!property.name) {
              return (
                <Typography key={i}>
                  Unknown Property of type {property.type}
                </Typography>
              );
            }
            return (
              <FinsightEntityFieldValueRenderer
                field={{
                  name: property.name,
                  schema: property,
                  type: field.type,
                }}
                value={valueMap[property.name ?? '']}
                editable={editable}
                key={property.name}
                onChange={async (v) =>
                  onChange?.({
                    ...valueMap,
                    [property.name ?? '']: v,
                  })
                }
              />
            );
          })}
        </Stack>
      );
    }

    return (
      <Stack>
        Values:
        {field.schema.properties?.map((property) => {
          return (
            <Typography key={property.name}>
              {property.name}: {`${valueMap[property.name ?? '']}`}
            </Typography>
          );
        })}
      </Stack>
    );
  }

  if (field.schema.type === 'string') {
    return (
      <StringRenderer
        field={field}
        value={value}
        editable={editable}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'number') {
    return (
      <NumberRenderer
        field={field}
        value={value}
        readonly={readonly}
        editable={editable}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'boolean') {
    return (
      <BooleanRenderer
        field={field}
        value={value}
        editable={editable}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'date') {
    return (
      <DateRenderer
        editable={editable}
        field={field}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'enum') {
    return (
      <EnumRenderer
        editable={editable}
        field={field}
        value={value}
        onChange={onChange}
      />
    );
  }

  if (field.schema.type === 'entity') {
    return (
      <EntityRenderer
        editable={editable}
        field={field}
        value={value}
        onChange={onChange}
      />
    );
  }

  return (
    <Typography>
      {field.name}: {`${value}`}
    </Typography>
  );
}
