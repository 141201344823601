import {
  alpha,
  createTheme,
  LinkProps,
  type ThemeOptions,
} from '@mui/material';
import { common } from '@mui/material/colors';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';

/* eslint-disable no-unused-vars */
declare module '@mui/material/styles' {
  interface Palette {
    dividerSecondary: string;
  }

  interface TypeBackground {
    inverted: string;
  }

  interface PaletteOptions {
    dividerSecondary?: string;
  }
}
/* eslint-enable no-unused-vars */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const finsightGreen = {
  50: '#d6fcee',
  100: '#97f5d5',
  200: '#02eeb9',
  300: '#00e39e',
  400: '#00d88b',
  500: '#00cd77',
  600: '#00bd6b',
  700: '#00a95c',
  800: '#007637',
  900: '#005b2b',
};

const finsightTeal = {
  '50': '#dff7f6',
  '100': '#b1eae8',
  '200': '#80dddb',
  '300': '#4dcecf',
  '400': '#26c3c7',
  '500': '#00b9c1',
  '600': '#00a9af',
  '700': '#009497',
  '800': '#008080',
  '900': '#055d57',
};

const baseTheme: ThemeOptions = {
  typography: {
    fontFamily: 'Bahnschrift',
    fontWeightRegular: 300,
  },
  palette: {
    primary: {
      ...finsightTeal,
      main: finsightTeal[800],
    },
    error: {
      main: '#ff0000',
    },
    action: {
      active: finsightTeal[600],
      hover: finsightTeal[50],
      selectedOpacity: 0.2,
    },
  },
  components: {
    MuiPopper: {
      defaultProps: {
        slotProps: {
          root: {
            className: 'MuiPopper-root-fixed',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        rounded: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: ({ theme }) => ({
            borderColor: theme.palette.divider,
            '&:not(.Mui-expanded)::before': {
              content: 'none',
            },
          }),
        },
      ],
    },
    MuiTouchRipple: {
      styleOverrides: {
        ripple: {
          // opacity: '0.5 !important',
          // color: 'black',
          filter: 'blur(3em)',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.shape.borderRadius,
        }),
      },
      variants: [
        {
          props: { selected: true },
          style: {
            '&, & .MuiListItemText-root .MuiTypography-root': {
              fontWeight: 'bold',
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: forwardRef<HTMLAnchorElement, LinkProps>(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          function FinsightLink({ color, href, ...rest }, ref) {
            return <Link ref={ref} to={href ?? ''} {...rest} />;
          },
        ),
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'text.secondary',
        underline: 'hover',
        component: forwardRef<HTMLAnchorElement, LinkProps>(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          function FinsightLink({ color, href, ...rest }, ref) {
            return <Link ref={ref} to={href ?? ''} {...rest} />;
          },
        ),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.divider,
          },
        }),
        input: ({ theme }) => ({
          '&:not(#a):-webkit-autofill': {
            '-webkit-box-shadow': `
              0 0 0 100px ${alpha(theme.palette.primary.main, 0.4)} inset,
              0 0 0 100px ${theme.palette.background.default} inset
            `,
          },
        }),
      },
    },
  },
};

const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primary: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      ...baseTheme.palette?.primary!,
      contrastText: '#fff',
    },
  },
  components: {
    ...baseTheme.components,
    MuiStack: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: alpha(finsightTeal[800], 0.1),
            borderRadius: '0.5em',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: alpha(finsightTeal[700], 0.5),
            borderRadius: '0.5em',
          },
        },
      },
    },
  },
});

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    primary: {
      ...finsightTeal,
      main: finsightTeal[700],
      contrastText: '#fff',
    },
    action: {
      active: finsightTeal[600],
      hover: alpha(finsightTeal[900], 0.4),
      selectedOpacity: 0.3,
    },
    divider: alpha(common.white, 0.25),
  },
  components: {
    ...baseTheme.components,
    MuiStack: {
      styleOverrides: {
        root: {
          '*::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: alpha(finsightTeal[100], 0.1),
            borderRadius: '0.5em',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: alpha(finsightTeal[500], 0.3),
            borderRadius: '0.5em',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            background: alpha(
              theme.palette.action.active,
              theme.palette.action.activatedOpacity,
            ),
          },
        }),
      },
    },
  },
});

export { darkTheme, lightTheme };
